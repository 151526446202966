export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Bad 2 Bad : Apocalypse",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://store.steampowered.com/app/2782610/Bad_2_Bad_Apocalypse/",
    domain: "bad2bad.com",
    gaId: "G-Z451K444PY",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
